import {useEffect, useState} from 'react';
import {handelTitle} from '../../utils';
import Header from '../../layouts/Header';
import CryptoRates from '../main/CryptoRates';
import Footer from '../../layouts/Footer';
import useSpreads from '../../utils/hooks/useSpreads';

const CURRENCY_API = process.env.REACT_APP_CURRENCY_API

const Rates = () => {
  const [currencyRates, setCurrencyRates] = useState([]);
  const { spreadsResult } = useSpreads();

  useEffect(() => {
    handelTitle('Zetlon Exchange')
  },[])

  useEffect(() => {
    if (document.title === 'Zetlon Exchange') {
      document.getElementsByTagName("body")[0].style.backgroundColor = '#0f0f12'
    }else{
      document.getElementsByTagName("body")[0].style.backgroundColor = '#0f0f12'
    }
  },[])

  useEffect(() => {
    const calculateRate = (value, rateFee) => {
      // If rateFee is 0, return 0; otherwise, apply the formula
      /*if (rateFee === 0) {
        return 0;
      }*/
      return value * (1 + rateFee / 100);
    };

    const fetchLatamCurrencyRates = async () => {
      try {
        const laCurrencies = ['MXN', 'PEN', 'CLP', 'COP', 'UYU', 'HNL', 'ARS', 'NGN', 'KES', 'DOP', 'USD', 'GTQ'];

        const response = await fetch(`${CURRENCY_API}${laCurrencies}&base_currency=USD`);
        const ratesResponse = await response.json();

        if (!spreadsResult || !Array.isArray(spreadsResult)) {
          console.warn('spreadsResult is undefined or not an array.');
          return [];
        }

        // Create a lookup table for rate fees
        const rateFeeLookup = Object.fromEntries(
            spreadsResult.map((item) => [item.currencyCode, item.rateFee])
        );

        // Transform the data
        return Object.values(ratesResponse?.data || {}).map((rate) => {
          const rateFee = rateFeeLookup?.[rate?.code] || 4; // Default rate fee is 4%

          if (!rate?.value || !rate?.code) {
            console.warn('Invalid rate data:', rate); // Optional: log invalid data
            return null; // Skip invalid rates
          }

          const threeDecimalCurrencies = ['PEN', 'USD']; // Add more currencies here

          const truncateRateValue = rate.value.toLocaleString('en-US', {
            minimumFractionDigits: threeDecimalCurrencies.includes(rate.code) ? 3 : 2,
            maximumFractionDigits: threeDecimalCurrencies.includes(rate.code) ? 3 : 2,
            useGrouping: false,
            roundingMode: "trunc"
          });

          const price = calculateRate(truncateRateValue, rateFee); // Use utility function

          const truncateRate = price.toLocaleString('en-US', {
            minimumFractionDigits: threeDecimalCurrencies.includes(rate.code) ? 3 : 2,
            maximumFractionDigits: threeDecimalCurrencies.includes(rate.code) ? 3 : 2,
            useGrouping: false,
            roundingMode: "trunc"
          });

          return {
            img: rate.code.toLowerCase(),
            title: rate.code.toUpperCase(),
            unit: `USDT/${rate.code}`,
            price: truncateRate, // Round to 2 or 3 decimal places
          };
        }).filter(item => item !== null); // Filter out null entries
      } catch (error) {
        console.error('Error fetching currency rates:', error);
        return [];
      }
    };

    // Call the fetch function and update state
    if (spreadsResult) {
      fetchLatamCurrencyRates().then((data) => {
        setCurrencyRates([...data]);
      });
    }
  }, [spreadsResult]);



  return (
      <>
        <Header/>
        <div className="HomeDemo2">
          <div className="container h-100 mt-100">
                <CryptoRates data={currencyRates}/>
            </div>
        </div>
        <Footer/>
      </>
  );
};

export default Rates
