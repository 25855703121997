import React, {useEffect, useState} from 'react';
const Rate = ({img, title, unit, price}) => {
  const [image, setImage] = useState('');

  // useEffect(() => {
  //   if(img){
  //    setImage(require(`../../../../assets/img/crypto/${img}.svg`));
  //   }
  // }, []);


  useEffect(() => {
    if (img) {
      try {
        require.resolve(`../../../../assets/img/crypto/${img}.svg`);
        setImage(require(`../../../../assets/img/crypto/${img}.svg`));
      } catch (e) {
        setImage(require('../../../../assets/img/crypto/usdt.svg'));
      }
    }
  }, [img]);


  return (
      <div className="crypto-box ">
        <div className="top">
          <div>
            <img src={`${image ? image : ''}`}
                 width={60}
                 height={60}
                 alt={title}/>
            <span className="rate-title">{title}</span>
            <span className="unit">{unit}</span>
          </div>
          <h3 className="price">{Intl.NumberFormat().format(price)}</h3>
          {/*<div className="bottom">
          <p>{bottom}</p>
          <p className={`sale ${status}`}>{sale}</p>
        </div>*/}
        </div>
      </div>
  )
}

export default Rate;
